function Filter(obj, app) {
    this.app = app;
    this.classes = this.app.Classes;
    this.filter = $(obj);

    this.widgetFolds = this.filter.find('.fold');
    this.checkboxSection = this.filter.find('.inputContainer input[type=checkbox]');
    this.btnReset = this.filter.find('.widget-reset');
    this.btnSubmit = this.filter.find('.widget-filter');
    this.init();
}

Filter.prototype = {
    init: function() {
        var self = this;
        self.validateDisabled();
        self.checkboxSection.on('click', function() {
            self.triggerCheckbox(this);
        });
        self.btnReset.on('click', function() {
            self.triggerReset(this);
        });
        self.validateCheckbox();
    },
    validateCheckbox: function() {
        this.widgetFolds.each(function( index, fold ) {
            var checkboxes = $(fold).find('.folder-content input[type=checkbox]');

            checkboxes.each(function( index ) {
                if ($(this).prop('checked') === true) {
                    $(fold).addClass('is-open');
                }
            });
        });
    },
    triggerCheckbox: function(checkbox) {
        var jqCheckbox = $(checkbox);
        var checkboxesContainer = jqCheckbox.parent().next();
        var fold = checkboxesContainer.parent();
    },
    triggerReset: function() {
        $('.js-facets-folder').find('input[type=checkbox]').prop('checked', false);
        this.btnSubmit.trigger('click');
    },
    validateDisabled: function() {
        this.widgetFolds.each(function(i, e) {
            var checkboxes = $(e).find('.folder-content input[type=checkbox]');
            var disabledCheckboxes = 0;
            checkboxes.each(function(i, e) {
                if ($(e).is(':disabled')) {
                    disabledCheckboxes++;
                }
            });
            if (disabledCheckboxes == checkboxes.length) {
                var checkboxSection = checkboxes.parent().parent().prev();
                var buttonSection = checkboxSection.prev();
                checkboxSection.find('input[type=checkbox]').prop('disabled', true);
                buttonSection.prop('disabled', true);
            }
        });
    }
};

VTT.extend.Components(Filter, 'Filter');
